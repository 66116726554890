<ng-container
  *ngIf="resource"
>
  <op-storage-information
    *ngFor="let infoBox of storageInformation | async"
    class="op-files-tab--storage-info-box"
    data-qa-selector="op-files-tab--storage-information"
    [viewModel]="infoBox"
  ></op-storage-information>

  <ul
    class="spot-list spot-list_compact op-file-list"
    data-qa-selector="file-list"
  >
    <li
      *ngFor="let fileLink of fileLinks$ | async; let i = index;"
      class="spot-list--item op-file-list--item"
      data-qa-selector="file-list--item"
      op-file-link-list-item
      [fileLink]="fileLink"
      [disabled]="disabled"
      [allowEditing]="allowEditing$ | async"
      (removeFileLink)="removeFileLink(fileLink)"
    ></li>
  </ul>

  <p
    *ngIf="(showLinkFilesAction | async) && (allowEditing$ | async)"
    class="spot-body-small op-file-list--actions"
  >
    <button
      type="button"
      class="spot-link op-file-list--action-button"
      (click)="openStorageLocation()"
    >
      <span class="spot-icon spot-icon_external-link"></span>
      <span [textContent]="text.actions.linkFile(storageType)"></span>
    </button>
    <button
      *ngIf="storageFileLinkingEnabled"
      type="button"
      class="spot-link op-file-list--action-button"
      data-qa-selector="op-file-list--link-existing-file-button"
      (click)="openLinkFilesDialog()"
    >
      <span class="spot-icon spot-icon_add-link"></span>
      <span [textContent]="text.actions.linkExisting"></span>
    </button>
  </p>
</ng-container>
