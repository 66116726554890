<input
  #dateInput
  [id]="id"
  [name]="name"
  [value]="initialDate || ''"
  [ngClass]="classes + ' op-input'"
  [size]="size"
  [required]="required"
  [disabled]="disabled"
  (click)="openOnClick()"
  (keydown.enter)="enterPressed.emit(dateValue)"
  (keydown.escape)="close()"
  (input)="onInputChange()"
  (blur)="onBlurred($event)"
  type="text"
>
